<template>
  <div class="bg">
    <Navbar></Navbar>
    <section class="section" style="margin-top: 6rem;">
      <div class="container has-text-centered">
        <h1 class="textTitle">Kim Jesteśmy?</h1>
        <br>
        <span class="textDesc">
          Pomożemy doprowadzić czystość twego samochodu do perfekcji.<br>
          Komfort jazdy w takim aucie wchodzi na inny poziom.<br>
          Chcemy, aby nikt nie przeoczył twojego pojazdu bez zachwytu.<br>
          <b>Jesteśmy po to, abyś zabłysnął na drogach.</b><br>
        </span>
      </div>
      <div class="columns is-vcentered mb-3 is-multiline reverse-columns" style="margin-top: 6rem">
        <div class="column is-12 is-half-widescreen right">
          <img src="/images/about/wnetrze.png" class="imgColumn">
        </div>
        <div class="column is-12 is-half-widescreen">
          <div class="has-text-centered has-text-left-widescreen">
            <h1 class="textTitle">Sprzątanie Wnętrza</h1>
            <br>
            <span class="textDesc">
              W zakres usługi wchodzi odkurzanie całego samochodu,<br>
              mycie szyb, pranie dywaników i tapicerki, czyszczenie wszystkich elementów
              nawet w miejscach trudno dostępnych oraz ich dressing.<br>
            </span>
          </div>
        </div>
      </div>
      <div class="columns is-vcentered is-multiline mt-3 mb-3">
        <div class="column is-12 is-half-widescreen textRight">
          <div class="has-text-centered has-text-left-widescreen">
            <h1 class="textTitle">Czysczenie Felg</h1>
            <br>
            <span class="textDesc">
                Kompleksowo zajmiemy się twoimi felgami.<br>
                Wyczyścimy je do perfekcji oraz nałożymy powłokę <br>
                ceramiczną w celu ich zabezpieczenia. 
            </span>
          </div>
        </div>
        <div class="column is-12 is-half-widescreen left">
          <img src="/images/about/felga.png" class="imgColumn">
        </div>
      </div>
      <div class="columns is-vcentered is-multiline mt-3 mb-3 reverse-columns">
        <div class="column is-12 is-half-widescreen right">
          <img src="/images/about/polowaAuta.png" class="imgColumn">
        </div>
        <div class="column is-12 is-half-widescreen">
          <div class="has-text-centered has-text-left-widescreen">
            <h1 class="textTitle">Mycie Samochodu</h1>
            <br>
            <span class="textDesc">
              Umyjemy twój samochód, nałożymy na niego wosk oraz<br>
              nabłyszczymy, aby przykuwał oko każdego przechodnia.<br>
              Nie zapomnimy również o myciu szyb.<br>
            </span>
          </div>
        </div>
      </div>
    </section>
    <Footer style="margin-top: 8rem"></Footer>
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue'
import Footer from '../components/Footer.vue'

export default {
  components: { Navbar, Footer },

}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/mixins";


.left {
  @include until-widescreen {
    display: flex; 
    justify-content: center;
  }
}

.right {
  display: flex; 
  justify-content: flex-end;

  @include until-widescreen {
    justify-content: center;
  }
}


.textRight {
  @include widescreen {
    display: flex; 
    justify-content: flex-end;
  }
}

.imgColumn {
  height: 300px;
  border-radius: 24px;
  margin-right: 100px;
  margin-left: 100px;
}

// .textDesc {
//   @include until-widescreen {
//     justify-content: center;
//   }
// }

@include until-widescreen { 
  .reverse-columns {
    flex-direction: column-reverse;
    display: flex;
  }
}
</style>
